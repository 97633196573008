<script lang="ts">
	import { classes } from '$lib/utils';
	import { getTranslate } from '@tolgee/svelte';
	import Button from '../Button/Button.svelte';
	import Rectangle from '$lib/assets/gardientRectangle.png';
	import ArrowIcon from '../Icon/ArrowIcon.svelte';
	import LineIcon from '../Icon/LineIcon.svelte';
	import LinkedInSignInIcon from '../Icon/LinkedInSignInIcon.svelte';
	import YoutubeIcon from '../Icon/YoutubeIcon.svelte';
	import { goto } from '$app/navigation';
	import LinkButton from '../Button/LinkButton.svelte';

	const { t } = getTranslate();

	export let additionalClass = '';
	export let tolgeePrefix = '__global';
	export let sectionTitle: string = $t(`${tolgeePrefix}.newsletter-section.title`);
	export let sectionDescription: string = $t(`${tolgeePrefix}.newsletter-section.text`);
	export let buttonTitle: string = $t(`${tolgeePrefix}.newsletter-section.btn`);
	export let buttonLink: string = '/auth/signup';
	export let isExternalLink: boolean = false;
</script>

<section class={classes('flex flex-col md:flex-row w-full py-0 mt-16', additionalClass)}>
	<div class="w-full">
		<img src={Rectangle} alt="gardient" class="w-[90%] h-[89px] mb-px" />
		<div class=" bg-black flex flex-row justify-between">
			<div class="text-white pl-5 md:mr-7">
				<h2 class="font-bold text-[40px] pt-16 uppercase leading-10">
					{@html sectionTitle}
				</h2>
				<p class="text-2xl font-normal mt-8 max-w-[40rem]">
					{@html sectionDescription}
				</p>

				{#if isExternalLink}
					<LinkButton href={buttonLink} additionalClass="!font-bold px-12 mt-16 uppercase">
						{@html buttonTitle}
					</LinkButton>
				{:else}
					<Button
						on:click={() => {
							goto(buttonLink);
						}}
						additionalClass="!font-bold px-12 mt-16 uppercase">{@html buttonTitle}</Button
					>
				{/if}

				<div class="flex flex-row lg:hidden gap-6 mt-12">
					<a
						href="https://www.youtube.com/@deepskydata"
						target="_blank"
						class="flex rounded-full items-center justify-center bg-purple w-12 h-12"
					>
						<YoutubeIcon />
					</a>
					<a
						href="https://www.linkedin.com/company/deepskydata"
						target="_blank"
						class="flex rounded-full items-center justify-center bg-purple w-12 h-12"
					>
						<LinkedInSignInIcon />
					</a>
				</div>
			</div>
			<div class="flex flex-col mr-[10%]">
				<div class="flex flex-row">
					<ArrowIcon additionalClass="w-[230px] mt-20 hidden lg:block" />
					<LineIcon additionalClass="mt-[-89px] h-40 hidden lg:block lg:h-[480px]" />
				</div>
				<div class="hidden lg:flex flex-row relative -mt-24 mb-12 gap-6 pt-12 w-full text-white">
					<a
						href="https://www.youtube.com/@deepskydata"
						target="_blank"
						class="absolute bottom-0 flex rounded-full items-center justify-center bg-purple w-12 h-12"
					>
						<YoutubeIcon />
					</a>
					<a
						href="https://www.linkedin.com/company/deepskydata"
						target="_blank"
						class="absolute bottom-0 left-20 flex rounded-full items-center justify-center bg-purple w-12 h-12"
					>
						<LinkedInSignInIcon />
					</a>
				</div>
			</div>
		</div>
	</div>
</section>
