<script lang="ts">
	import { classes } from '$lib/utils';
	export let additionalClass = '';
</script>

<svg
	class={classes('w-6 h-6', additionalClass)}
	height="100%"
	width="100%"
	version="1.1"
	viewBox="0 0 29 22"
	fill="currentColor"
	><use xlink:href="#ytp-id-50" />
	<g clip-path="url(#clip0_8851_19548)">
		<path
			d="M14.5529 0.732422C16.3781 0.772749 18.2039 0.794556 20.0283 0.859676C21.3527 0.907173 22.676 0.999776 23.9985 1.09626C24.687 1.14645 25.3797 1.20828 26.0356 1.4598C27.333 1.95747 28.1212 2.91875 28.4196 4.31407C28.6453 5.36975 28.7722 6.44006 28.8595 7.51635C28.9803 9.00099 29.0178 10.4883 28.9925 11.9774C28.9645 13.6156 28.8734 15.2502 28.6427 16.8722C28.5542 17.4954 28.4752 18.1337 28.276 18.7231C27.807 20.1116 26.8192 20.9181 25.427 21.1843C24.4387 21.3731 23.4377 21.4504 22.4362 21.5188C19.7645 21.7017 17.0888 21.7477 14.4127 21.7288C12.6305 21.7163 10.8476 21.6885 9.06676 21.6228C7.68493 21.572 6.30454 21.4678 4.92588 21.3554C4.32016 21.3061 3.70233 21.2434 3.12169 21.0714C1.72256 20.6564 0.872354 19.6635 0.552051 18.195C0.328328 17.1695 0.219927 16.126 0.138914 15.0787C0.0192688 13.529 -0.0176338 11.9771 0.00744844 10.4235C0.0293594 9.06939 0.084425 7.71619 0.23982 6.37106C0.330347 5.58722 0.407611 4.79053 0.610864 4.03417C1.01939 2.51578 2.01864 1.59005 3.52531 1.33793C4.50928 1.17333 5.50652 1.07894 6.50116 0.999179C9.18064 0.784399 11.8662 0.756618 14.5529 0.732422ZM11.6889 15.7461C14.2271 14.2387 16.7379 12.7475 19.2735 11.2414C16.7313 9.73165 14.2184 8.23955 11.6889 6.73729V15.7458V15.7461Z"
		/>
		<path
			d="M11.6875 15.7463V6.73779C14.2171 8.23976 16.7299 9.73216 19.2721 11.2419C16.7365 12.7477 14.2254 14.2392 11.6875 15.7466V15.7463Z"
			fill="#8F00FF"
		/>
	</g>
	<defs>
		<clipPath id="clip0_8851_19548">
			<rect width="29" height="21" fill="white" transform="translate(0 0.732422)" />
		</clipPath>
	</defs>
</svg>
