<script lang="ts">
	import { classes } from '$lib/utils';
	export let additionalClass = '';
</script>

<svg
	width="232"
	height="253"
	viewBox="0 0 232 253"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
	class={classes('', additionalClass)}
>
	<path
		d="M115.669 0V27.4911H47.487L231.5 212.5L231.5 252.877L27.255 47.898V116.611H0V0H115.669Z"
		fill="white"
	/>
</svg>
