<script lang="ts">
	import { classes } from '$lib/utils';
  import bannerInfo from '$lib/assets/banner-info.png';
	import Button from '../Button/Button.svelte';
	export let title: string;
	export let subtitle: string = '';
	export let buttonText: string | undefined = undefined;
	export let href: string = '';

	export let buttonDesign: 'primary' | 'secondary' | 'white' = 'primary';

	export let additionalClass = '';
	export let additionalTitleClass = '';

	/** the css classes for screens below lg - 1024px */
	export let additionalClassBelowLg = 'bg-gradient-2 text-white';
	/** the css classes for screens larger than lg - 1024px */
	export let additionalClassLg: string;

	let innerWidth: number;
</script>

<svelte:window bind:innerWidth />
<section
	class={classes(
		additionalClass,
		'bg-svg layout-hero py-[120px] md:py-[200px] mt-16',
		// TODO: use tailwind sizes from config
		innerWidth < 1024 ? additionalClassBelowLg : additionalClassLg
	)}
>
  <img alt="Deepskydata soon to be relaunched as NoSlides" src={bannerInfo} class="w-full h-auto absolute left-0 top-16 md:object-cover object-none min-h-[6rem]" width="1440" height="88" />
	<div class={innerWidth < 1024 ? 'x-container' : ''}>
		<h1
			class={classes(
				additionalTitleClass,
				'mb-6 text-4xl xl:text-6xl 2xl:text-7xl font-bold uppercase'
			)}
		>
			{@html title}
		</h1>
		{#if subtitle}
			<p class="mb-12 text-sm md:text-base">{subtitle}</p>
		{/if}
		{#if href && buttonText}
			<a {href}><Button design={buttonDesign} additionalClass="uppercase">{buttonText}</Button></a>
		{:else if buttonText}
			<Button design={buttonDesign} on:click additionalClass="uppercase">{buttonText}</Button>
		{/if}
	</div>
</section>

<style>
	@media screen and (min-width: 1024px) {
		.layout-hero {
			height: 56.25vw;
			max-height: 100vh;
			background-position: bottom;
			padding: 0 50vw 0 10vw;
			@apply flex flex-col justify-center items-start;
		}
	}
</style>
