<script lang="ts">
	import { classes } from '$lib/utils';
	export let additionalClass = '';
</script>

<svg
	width="1"
	height="480"
	viewBox="0 0 1 480"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
	class={classes('', additionalClass)}
>
	<line x1="0.5" y1="480" x2="0.500021" y2="-2.18557e-08" stroke="white" />
</svg>
